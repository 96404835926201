exports.ms = {
	b1: {
		key: "b1",
		abbr: "verbal",
	},
	b2: {
		key: "b2",
		abbr: "social",
	},
	b3: {
		key: "b3",
		abbr: "physical",
	},
	b4: {
		key: "b4",
		abbr: "online",
	},
	b5: {
		key: "b5",
		abbr: "why",
	},
	b6: {
		key: "b6",
		abbr: "where",
	},
	b7: {
		key: "b7",
		abbr: "who",
	},
	b8: {
		key: "b8",
		abbr: "response",
	},
	b11: {
		key: "b11",
		abbr: "school rules",
	},
	b12: {
		key: "b12",
		abbr: "bullying statements",
	},
	b13: {
		key: "b13",
		abbr: "learning more",
	},
	b21: {
		key: "b21",
		abbr: "personal response",
	},
	b22: {
		key: "b22",
		abbr: "adult response",
	},
	b23: {
		key: "b23",
		abbr: "reasons for bullying",
	},
	rn50: {
		key: "rn50",
		abbr: "most rewarding",
	},
	rn51: {
		key: "rn51",
		abbr: "most challenging",
	},
	rn52: {
		key: "rn52",
		abbr: "one suggestion",
	},
	rn110: {
		key: "rn110",
		abbr: "one suggestion",
	},
	rn111: {
		key: "rn111",
		abbr: "harassed, bullied or threatened"
	},
	rn53: {
		key: "rn53",
		abbr: "other",
	},
	cantril: {
		key: "cantril",
		abbr: "self",
	},
	cantril1: {
		key: "cantril1",
		abbr: "life",
	},
	cantril2: {
		key: "cantril2",
		abbr: "job",
	},
	cantril3: {
		key: "cantril3",
		abbr: "workplace",
	},
	cantril4: {
		key: "cantril4",
		abbr: "child",
	},
	cantril5: {
		key: "cantril5",
		abbr: "parent/caregiver",
	},
	cantril6: {
		key: "cantril6",
		abbr: "school",
	},
	rn60: {
		key: "rn60",
		abbr: "at school",
	},
	rn61: {
		key: "rn61",
		abbr: "wellbeing",
	},
	rn62: {
		key: "rn62",
		abbr: "relationship",
	},
	rn81: {
		key: "rn81",
		abbr: "love and respect"
	},
	rn82: {
		key: "rn82",
		abbr: "parent/caregiver opinion"
	},
	rn83: {
		key: "rn83",
		abbr: "downloads"
	},
	rn84: {
		key: "rn84",
		abbr: "online games"
	},
	rn85: {
		key: "rn85",
		abbr: "sleep"
	},
	rn86: {
		key: "rn86",
		abbr: "happy"
	},
	rn206: {
		key: "rn206",
		abbr: "support"
	},
	rn87: {
		key: "rn87",
		abbr: "care"
	},
	rn88: {
		key: "rn88",
		abbr: "resources"
	},
	rn89: {
		key: "rn89",
		abbr: "overall"
	},
	rn54: {
		key: "rn54",
		abbr: "recommend"
	},
	rn55: {
		key: "rn55",
		abbr: "value alignmnet"
	},
	rn56: {
		key: "rn56",
		abbr: "feel valued"
	},
	rn57: {
		key: "rn57",
		abbr: "life balance"
	},
	rn74: {
		key: "rn74",
		abbr: "parenting/caregiving statements (1)",
	},
	rn75: {
		key: "rn75",
		abbr: "parenting/caregiving statements (2)",
	},
	rn76: {
		key: "rn76",
		abbr: "school statements",
	},
	rn71: {
		key: "rn71",
		abbr: "parent/caregiver controls",
	},
	rn72: {
		key: "rn72",
		abbr: "risk exposure",
	},
	rn73: {
		key: "rn73",
		abbr: "various statements",
	},
	rn77: {
		key: "rn77",
		abbr: "like most",
	},
	rn78: {
		key: "rn78",
		abbr: "personal suggestion",
	},
	rn79: {
		key: "rn79",
		abbr: "school suggestion",
	},
	rn200: {
		key: "rn200",
		abbr: "like most",
	},
	rn201: {
		key: "rn201",
		abbr: "school suggestion",
	},
	rn202: {
		key: "rn202",
		abbr: "personal suggestion",
	},
	rn203: {
		key: "rn203",
		abbr: "favourite family things",
	},
	rn204: {
		key: "rn204",
		abbr: "additional queries/concerns",
	},
	sm4: {
		key: "sm4",
		abbr: "social media usage"
	},
	"pdf-mentalhealth": {
		key: "pdf-mentalhealth",
		label: "Mental Health",
	},
	"pdf-annualoutcomes": {
		key: "pdf-annualoutcomes",
		label: "TOMS",
	},
	"pdf-snapshot": {
		key: "pdf-snapshot",
		label: "Snapshot",
	},
	"pdf-demographics": {
		key: "pdf-demographics",
		label: "Demographics",
	},
	"pdf-resilience": {
		key: "pdf-resilience",
		label: "Resilience Score",
	},
	"pdf-healthy-mind": {
		key: "pdf-healthy-mind",
		label: "Healthy Mind Score",
	},
	"pdf-ready-to-learn": {
		key: "pdf-ready-to-learn",
		label: "Ready to Learn Score",
	},
	"pdf-cross-sectional": {
		key: "pdf-cross-sectional",
		label: "Cross-Sectional",
	},
	"pdf-longitudinal": {
		key: "pdf-longitudinal",
		label: "Longitudinal",
	},
	"pdf-time-lag": {
		key: "pdf-time-lag",
		label: "Time-Lag",
	},
	"pdf-snapshot-short": {
		key: "pdf-snapshot-short",
		label: "Snapshot",
	},
	"pdf-cross-sectional-short": {
		key: "pdf-cross-sectional-short",
		label: "Cross-Sectional",
	},
	"pdf-longitudinal-short": {
		key: "pdf-longitudinal-short",
		label: "Longitudinal",
	},
	"pdf-time-lag-short": {
		key: "pdf-time-lag-short",
		label: "Time-Lag",
	},
	"pdf-overview": {
		key: "pdf-overview",
		label: "Overview",
	},
	"pdf-swat": {
		key: "pdf-swat",
		label: "SWAT"
	},
	snapshot: {
		key: "snapshot",
		label: "Snapshot",
	},
	"resilience": {
		key: "resilience",
		label: "Construct View",
		class: "small-caps",
	},
	"ready-to-learn": {
		key: "ready-to-learn",
		label: "Construct View",
		class: "small-caps",
	},
	"snapshot-short": {
		key: "snapshot-short",
		label: "Snapshot",
	},
	"executive-summary": {
		key: "executive-summary",
		label: "Help Me Choose",
		class: "small-caps",
	},
	"executive-summary-short": {
		key: "executive-summary-short",
		label: "Help Me Choose",
		class: "small-caps",
	},
	CHS: {
		key: "CHS",
		label: "Construct View",
		class: "small-caps",
	},
	"CSI-4": {
		key: "CSI-4",
		label: "Construct View",
		class: "small-caps",
	},
	"GAD-2": {
		key: "GAD-2",
		label: "Construct View",
		class: "small-caps",
	},
	"PHQ-2": {
		key: "PHQ-2",
		label: "Construct View",
		class: "small-caps",
	},
	"healthy-mind": {
		key: "healthy-mind",
		label: "Construct View",
		class: "small-caps",
	},
	AHS: {
		key: "AHS",
		label: "Construct View",
		class: "small-caps",
	},
	comments: {
		key: "comments",
		label: "Comments",
	},
	"cantril-single": {
		key: "cantril",
		label: "<strong>Life Satisfaction</strong> (Cantril*)",
	},
	"IINS-single": {
		key: "IINS",
		label: "<strong>Connection to Nature</strong> (IINS*)",
	},

	"pdf-snapshot-ipe-mi7": {
		key: "pdf-snapshot-ipe-mi7",
		label: "Snapshot",
	},
	"executive-summary-ipe-mi7": {
		key: "executive-summary-ipe-mi7",
		label: "Help Me Choose",
		class: "small-caps",
	},
	"pdf-cross-sectional-ipe-mi7": {
		key: "pdf-cross-sectional-ipe-mi7",
		label: "Cross-Sectional",
	},
	"pdf-longitudinal-ipe-mi7": {
		key: "pdf-longitudinal-ipe-mi7",
		label: "Longitudinal",
	},
	"pdf-time-lag-ipe-mi7": {
		key: "pdf-time-lag-ipe-mi7",
		label: "Time-Lag",
	},
	"snapshot-ipe-mi7": {
		key: "snapshot-ipe-mi7",
		label: "Snapshot",
	},

	ipe43: {
		key: "ipe43",
		abbr: "good things",
	},
	ipe44: {
		key: "ipe44",
		abbr: "unhappy things",
	},
	ipe45: {
		key: "ipe45",
		abbr: "suggestion",
	},

	"pdf-snapshot-jgi-nw-lower": {
		key: "pdf-snapshot-jgi-nw-lower",
		label: "Snapshot",
	},
	"pdf-snapshot-jgi-nw-upper": {
		key: "pdf-snapshot-jgi-nw-upper",
		label: "Snapshot",
	},
	"pdf-snapshot-jgi-nw": {
		key: "pdf-snapshot-jgi-nw",
		label: "Snapshot",
	},
	"executive-summary-jgi-nw-lower": {
		key: "executive-summary-jgi-nw-lower",
		label: "Help Me Choose",
		class: "small-caps",
	},
	"executive-summary-jgi-nw-upper": {
		key: "executive-summary-jgi-nw-upper",
		label: "Help Me Choose",
		class: "small-caps",
	},
	"executive-summary-jgi-nw": {
		key: "executive-summary-jgi-nw",
		label: "Help Me Choose",
		class: "small-caps",
	},
	"pdf-cross-sectional-jgi-nw-lower": {
		key: "pdf-cross-sectional-jgi-nw-lower",
		label: "Cross-Sectional",
	},
	"pdf-cross-sectional-jgi-nw-upper": {
		key: "pdf-cross-sectional-jgi-nw-upper",
		label: "Cross-Sectional",
	},
	"pdf-cross-sectional-jgi-nw": {
		key: "pdf-cross-sectional-jgi-nw",
		label: "Cross-Sectional",
	},
	"pdf-longitudinal-jgi-nw-lower": {
		key: "pdf-longitudinal-jgi-nw-lower",
		label: "Longitudinal",
	},
	"pdf-longitudinal-jgi-nw-upper": {
		key: "pdf-longitudinal-jgi-nw-upper",
		label: "Longitudinal",
	},
	"pdf-time-lag-jgi-nw-lower": {
		key: "pdf-time-lag-jgi-nw-lower",
		label: "Time-Lag",
	},
	"pdf-time-lag-jgi-nw-upper": {
		key: "pdf-time-lag-jgi-nw-upper",
		label: "Time-Lag",
	},
	"pdf-time-lag-jgi-nw": {
		key: "pdf-time-lag-jgi-nw",
		label: "Time-Lag",
	},

	"snapshot-jgi-nw-lower": {
		key: "snapshot-jgi-nw-lower",
		label: "Snapshot",
	},
	"snapshot-jgi-nw-upper": {
		key: "snapshot-jgi-nw-upper",
		label: "Snapshot",
	},
	"snapshot-jgi-nw": {
		key: "snapshot-jgi-nw",
		label: "Snapshot",
	},

	jg26: {
		key: "jg26",
		abbr: "like doing",
	},
	jg27: {
		key: "jg27",
		abbr: "worries",
	},
	jg134: {
		key: "jg134",
		abbr: "lesson plans",
	},
	jg135: {
		key: "jg135",
		abbr: "other resources",
	},
	jg136: {
		key: "jg136",
		abbr: "anything else",
	},

	"pdf-snapshot-learning-curve-wellbeing": {
		key: "pdf-snapshot-learning-curve-wellbeing",
		label: "Snapshot",
	},
	"executive-summary-learning-curve-wellbeing": {
		key: "executive-summary-learning-curve-wellbeing",
		label: "Help Me Choose",
		class: "small-caps",
	},
	"pdf-cross-sectional-learning-curve-wellbeing": {
		key: "pdf-cross-sectional-learning-curve-wellbeing",
		label: "Cross-Sectional",
	},
	"pdf-longitudinal-learning-curve-wellbeing": {
		key: "pdf-longitudinal-learning-curve-wellbeing",
		label: "Longitudinal",
	},
	"pdf-time-lag-learning-curve-wellbeing": {
		key: "pdf-time-lag-learning-curve-wellbeing",
		label: "Time-Lag",
	},
	"snapshot-learning-curve-wellbeing": {
		key: "snapshot-learning-curve-wellbeing",
		label: "Snapshot",
	},
	"pdf-snapshot-ks": {
		key: "pdf-snapshot-ks",
		label: "Snapshot",
	},
	"snapshot-ks": {
		key: "snapshot-ks",
		label: "Snapshot",
	},
	k13: {
		key: "k13",
		label: "Acts of Kindness",
	},
	k14: {
		key: "k14",
		label: "Kindness to Self",
	},
	k15: {
		key: "k15",
		label: "Kindness to Others",
	},
	k16: {
		key: "k16",
		label: "Meaning of Kindness",
	},
	k17: {
		key: "k17",
		label: "Feeling from Kindness",
	}
};
