let questions = require("./qs.js");
let qs = questions.qs;

let tranches = [
	{
		group: "student",
		surveys: ["student-resilience", "short-resilience"],
		hVar: "grade",
		items: [
			qs.ry35,
			qs.ry36,
			qs.ry37,
			qs.ry38,
			qs.ry39,
			qs.ry40,
			qs.ry42,
			qs.ry43,
			qs.sm1,
			qs.sm2,
			qs.sm3,
		],
	},
	{
		group: "student",
		surveys: [
			"student-resilience",
			"short-resilience",
			"learning-curve-wellbeing",
		],
		hVar: "grade",
		items: [qs.ry25],
	},
	{
		group: "student",
		surveys: [
			"student-resilience",
			"short-resilience",
			"no-bullying",
			"relationships-and-no-bullying",
			"learning-curve-wellbeing",
		],
		hVar: "grade",
		items: [qs.ry3],
	},
	{
		group: "student",
		surveys: [
			"student-resilience",
			"short-resilience",
			"no-bullying",
			"relationships-and-no-bullying",
			"JGI_NatureWellbeingUpper",
			"learning-curve-wellbeing",
		],
		hVar: "grade",
		items: [qs.ry2, qs.ry14, qs.ry6],
	},
	{
		group: "student",
		surveys: [
			"student-resilience",
			"no-bullying",
			"relationships-and-no-bullying",
			"learning-curve-wellbeing",
		],
		hVar: "grade",
		items: [qs.cyb1],
	},
	{
		group: "student",
		surveys: [
			"no-bullying",
			"relationships-and-no-bullying",
			"learning-curve-wellbeing",
		],
		hVar: "grade",
		isNew: true,
		items: [qs.cyb4, qs.cyb8, qs.cyb9],
	},
	{
		group: "student",
		surveys: [
			"student-resilience",
			"short-resilience",
			"relationships-and-no-bullying",
			"phillips-foundation"
		],
		hVar: "grade",
		items: [
			qs.cop1,
			qs.cop2,
			qs.cop3,
			qs.cop4,
		]
	},
	{
		group: "student",
		surveys: [
			"student-resilience",
			"short-resilience",
			"relationships-and-no-bullying",
		],
		hVar: "grade",
		items: [
			qs.for2,
			qs.ry27,
			qs.phq3,
			qs.ry32,
			qs.sun9,
			qs.sun10,
		],
	},
	{
		group: "student",
		surveys: [
			"student-resilience",
			"short-resilience",
			"relationships-and-no-bullying",
			"IPE-Mi7",
		],
		hVar: "grade",
		items: [qs.aud1, qs.aud2, qs.aud3, qs.gam1, qs.gam2],
	},
	{
		group: "student",
		surveys: [
			"student-resilience",
			"short-resilience",
			"relationships-and-no-bullying",
			"learning-curve-wellbeing",
		],
		hVar: "grade",
		items: [qs.ry4, qs.ry5, qs.ry9, qs.ry13, qs.sun3, qs.ry16, qs.for1],
	},
	{
		group: "student",
		surveys: [
			"student-resilience",
			"short-resilience",
			"relationships-and-no-bullying",
			"JGI_NatureWellbeingUpper",
			"learning-curve-wellbeing",
		],
		hVar: "grade",
		items: [qs.ry17, qs.ry18, qs.ry19, qs.ry20, qs.ry28],
	},
	{
		group: "student",
		surveys: [
			"student-resilience",
			"short-resilience",
			"relationships-and-no-bullying",
			"IPE-Mi7",
			"JGI_NatureWellbeingUpper",
			"learning-curve-wellbeing",
			"phillips-foundation",
		],
		hVar: "grade",
		items: [qs.ph3, qs.ph4],
	},
	{
		group: "student",
		surveys: ["student-resilience"],
		hVar: "grade",
		items: [
			qs.ry10,
			qs.ry11,
			qs.ry12,
			qs.ry15,
			qs.ry22,
			qs.tru2,
			qs.ry26,
			qs.ry29,
			qs.phq4,
			qs.phq5,
			qs.phq7,
		],
	},
	{
		group: "student",
		surveys: ["student-resilience", "learning-curve-wellbeing"],
		hVar: "grade",
		items: [
			qs.ry7,
			qs.ry8,
			qs.ry23,
			qs.ry24,
			qs.ry30,
			qs.ry31,
			qs.sun4,
			qs.sun1,
			qs.tru1,
		],
	},
	{
		group: "student",
		surveys: ["student-resilience", "JGI_NatureWellbeingUpper"],
		hVar: "grade",
		items: [qs.sun5],
	},
	{
		group: "student",
		surveys: [
			"student-resilience",
			"JGI_NatureWellbeingUpper",
			"learning-curve-wellbeing",
		],
		hVar: "grade",
		items: [qs.ry21],
	},
	{
		group: "student",
		surveys: ["student-resilience", "IPE-Mi7"],
		hVar: "grade",
		items: [qs.sun11, qs.sun12],
	},
	{
		group: "student",
		surveys: ["IPE-Mi7"],
		hVar: "grade",
		isNew: true,
		items: [
			qs.ipe26,
			qs.ipe27,
			qs.ipe37,
			qs.ipe38,
			qs.ipe28,
			qs.ipe29,
			qs.ipe30,
			qs.ipe2,
			qs.ipe3,
			qs.ipe4,
			qs.ipe5,
			qs.ipe6,
			qs.ipe7,
			qs.ipe8,
			qs.ipe36,
			qs.ipe9,
			qs.ipe10,
			qs.ipe11,
			qs.ipe12,
			qs.ipe13,
			qs.ipe14,
			qs.ipe15,
			qs.ipe16,
			qs.ipe17,
			qs.ipe18,
			qs.ipe19,
			qs.ipe20,
			qs.ipe21,
			qs.ipe22,
			qs.ipe23,
			qs.ipe24,
			qs.ipe25,
			qs.ipe39,
			qs.ipe40,
			qs.ipe41,
			qs.ipe42,
			qs.ipe31,
			qs.ipe32,
			qs.ipe33,
			qs.ipe34,
			qs.ipe35,
			qs.ipe46,
			qs.ipe47,
			qs.ipe48,
			qs.ipe49,
			qs.ipe50,
			qs.ipe51,
			qs.ipe52,
			qs.ipe53,
			qs.ipe54,
			qs.ipe55,
			qs.ipe56,
			qs.ipe57,
			qs.ipe58,
			qs.ipe59,
			qs.ipe60,
			qs.ipe61,
			qs.ipe62,
			qs.ipe63,
			qs.ipe64,
			qs.ipe65,
			qs.ipe66,
			qs.ipe67,
			qs.ipe68,
			qs.ipe69,
			qs.ipe70,
			qs.ipe71,
			qs.ipe72,
			qs.ipe73,
			qs.ipe74,
			qs.ipe75,
		],
	},
	{
		group: "staff",
		surveys: ["school-staff-resilience"],
		hVar: "agegroup",
		isNew: false,
		items: [
			qs.tp1,
			qs.tp2,
			qs.tp3,
			qs.tp4,
			qs.tp5,
			qs.tp6,
			qs.tp7,
			qs.tp8,
			qs.tp9,
			qs.tp10,
			qs.tp11,
			qs.tp12,
			qs.tp13,
			qs.tp14,
			qs.tp15,
			qs.rn1,
			qs.rn2,
			qs.rn3,
			qs.rn4,
			qs.rn5,
			qs.rn6,
			qs.rn7,
			qs.ph1,
			qs.ph2,
			qs.ph3,
			qs.ph4,
			qs.phq3,
			qs.phq4,
			qs.phq5,
			qs.phq7,
			qs.ahs1,
			qs.ahs3,
			qs.ahs4,
			qs.ahs5,
			qs.rn8,
			qs.rn9,
			qs.rn10,
			qs.rn11,
			qs.rn12,
			qs.rn13,
			qs.rn14,
			qs.rn15,
			qs.rn16,
			qs.rn17,
			qs.rn18,
			qs.rn19,
			qs.rn20,
			qs.rn21,
			qs.rn22,
			qs.rn23,
			qs.rn24,
			qs.rn25,
			qs.rn26,
			qs.rn27,
			qs.rn28,
			qs.rn29,
			qs.rn30,
			qs.rn31,
			qs.rn32,
			qs.rn33,
			qs.rn34,
			qs.rn35,
			qs.rn36,
			qs.rn37,
			qs.rn38,
			qs.rn39,
			qs.rn40,
			qs.rn41,
			qs.rn42,
			qs.rn43,
			qs.rn44,
			qs.rn45,
		],
	},

	{
		group: "employee",
		surveys: ["workplace-resilience"],
		hVar: "agegroup",
		isNew: true,
		items: [
			qs.rn1,
			qs.rn2,
			qs.rn3,
			qs.rn4,
			qs.rn5,
			qs.rn6,
			qs.rn7,
			qs.ph1,
			qs.ph2,
			qs.ph3,
			qs.ph4,
			qs.phq3,
			qs.phq4,
			qs.phq5,
			qs.phq7,
			qs.ahs1,
			qs.ahs3,
			qs.ahs4,
			qs.ahs5,
			qs.rn8,
			qs.rn9,
			qs.rn11,
			qs.rn12,
			qs.rn13,
			qs.rn90,
			qs.rn91,
			qs.rn92,
			qs.rn17,
			qs.rn18,
			qs.rn19,
			qs.rn93,
			qs.rn94,
			qs.rn22,
			qs.rn23,
			qs.rn95,
			qs.rn25,
			qs.rn96,
			qs.rn97,
			qs.rn98,
			qs.rn99,
			qs.rn30,
			qs.rn100,
			qs.rn32,
			qs.rn33,
			qs.rn34,
			qs.rn101,
			qs.rn36,
			qs.rn102,
			qs.rn38,
			qs.rn103,
			qs.rn104,
			qs.rn105,
			qs.rn106,
			qs.rn107,
			qs.rn108,
			qs.rn109,
		],
	},

	{
		group: "parent",
		surveys: ["parent-resilience"],
		hVar: "grade",
		isNew: true,
		items: [
			qs.rn55,
			qs.rn56,
			qs.rn85,
			qs.ph1,
			qs.ph2,
			qs.ph3,
			qs.ph4,
			qs.rn57,
			qs.rn86,
			qs.rn87,
			qs.rn88,
			qs.rn89,
			qs.rn54,
			qs.rn206,
			qs.rn63,
			qs.rn65,
			qs.rn66,
			qs.rn67,
			qs.rn83,
			qs.rn84,
			qs.rn70,
		],
	},

	{
		group: "student",
		surveys: ["JGI_NatureWellbeingLower"],
		hVar: "grade",
		isNew: true,
		items: [
			qs.jg28,
			qs.jg29,
			qs.jg30,
			qs.jg31,
			qs.jg32,
			qs.jg33,
			qs.jg34,
			qs.jg35,
			qs.jg36,
			qs.jg37,
			qs.jg38,
			qs.jg39,
			qs.jg40,
			qs.jg50,
			qs.jg51,
			qs.jg52,
			qs.jg53,
			qs.jg54,
			qs.jg55,
			qs.jg56,
			qs.jg57,
			qs.jg58,
			qs.jg59,
			qs.jg60,
			qs.jg61,
			qs.jg62,
			qs.jg63,
			qs.jg64,
			qs.jg65,
			qs.jg66,
			qs.jg67,
			qs.jg68,
		],
	},
	{
		group: "student",
		surveys: ["JGI_NatureWellbeingUpper"],
		hVar: "grade",
		isNew: true,
		items: [
			qs.jg1,
			qs.jg2,
			qs.jg3,
			qs.jg4,
			qs.jg5,
			qs.jg6,
			qs.jg7,
			qs.jg8,
			qs.jg9,
			qs.jg10,
			qs.jg11,
			qs.jg12,
			qs.jg13,
			qs.jg14,
			qs.jg15,
			qs.jg16,
			qs.jg17,
			qs.jg18,
			qs.jg19,
			qs.jg20,
			qs.jg21,
			qs.jg22,
			qs.jg23,
			qs.jg24,
			qs.jg25,
			qs.ry33,
			qs.ry34,
		],
	},
	{
		group: "staff",
		surveys: ["JGI_NatureWellbeingStaff"],
		hVar: "agegroup",
		isNew: true,
		items: [
			qs.jg100,
			qs.jg101,
			qs.jg102,
			qs.jg103,
			qs.jg104,
			qs.jg105,
			qs.jg106,
			qs.jg107,
			qs.jg109,
			qs.jg110,
			qs.jg111,
			qs.jg112,
			qs.jg113,
			qs.jg114,
			qs.jg115,
			qs.jg116,
			qs.jg117,
			qs.jg118,
			qs.jg119,
			qs.jg120,
			qs.jg121,
			qs.jg122,
			qs.jg123,
			qs.jg124,
			qs.jg125,
			qs.jg126,
			qs.jg127,
			qs.jg128,
			qs.jg129,
			qs.jg130,
			qs.jg131,
			qs.jg132,
			qs.jg133,
		],
	},
	{
		group: "student",
		surveys: ["kind-schools"],
		hVar: "grade",
		isNew: true,
		items: [
			qs.k1,
			qs.ry14,
			qs.ry21,
			qs.ry26,
			qs.k2,
			qs.k3,
			qs.k19,
			qs.k4,
			qs.k5,
			qs.k6,
			qs.k7,
			qs.ry10,
			qs.k8,
			qs.k9,
			qs.k21,
			qs.k10,
			qs.k11,
		],
	},
	{
		group: "student",
		surveys: [
			"student-resilience",
			"short-resilience",
			"relationships-and-no-bullying",
			"IPE-Mi7",
			"JGI_NatureWellbeingUpper",
			"learning-curve-wellbeing",
			"phillips-foundation",
		],
		hVar: "grade",
		isNew: true,
		items: [qs.chs1, qs.chs2, qs.chs3, qs.chs4, qs.chs5, qs.chs6],
	},
	{
		group: "student",
		surveys: [
			"student-resilience",
			"short-resilience",
			"relationships-and-no-bullying",
			"IPE-Mi7",
			"JGI_NatureWellbeingUpper",
			"learning-curve-wellbeing",
			"kind-schools",
			"phillips-foundation",
		],
		hVar: "grade",
		isNew: true,
		items: [qs.ph1, qs.ph2],
	},
	{
		group: "student",
		surveys: ["student-resilience", "short-resilience", "kind-schools"],
		hVar: "grade",
		isNew: true,
		items: [qs.k12],
	},
	{
		group: "swat",
		surveys: ["school-wellbeing-audit-tool"],
		isNew: true,
		hVar: "agegroup",
		items: [qs.swat1,qs.swat2,qs.swat3,qs.swat4,qs.swat5,qs.swat6,qs.swat7,qs.swat8,qs.swat9,qs.swat10,qs.swat11,qs.swat12,qs.swat13,qs.swat14,qs.swat15],
	}
];

let histograms = [
	{
		key: "b1",
		group: "student",
		hVar: "grade",
		surveys: [
			"no-bullying",
			"relationships-and-no-bullying",
			"learning-curve-wellbeing",
		],
		text: "Called names, teased, picked on, made fun of, embarrassed, insulted or verbally threatened.",
		responses: ["Daily", "Weekly", "Once or more a term", "Never"],
		type: "likert",
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 3,
		dataset: "be1",
		isNew: true,
	},
	{
		key: "b2",
		group: "student",
		hVar: "grade",
		surveys: [
			"no-bullying",
			"relationships-and-no-bullying",
			"learning-curve-wellbeing",
		],
		text: "Left out of a group of friends on purpose, ignored or had rumours spread about you.",
		responses: ["Daily", "Weekly", "Once or more a term", "Never"],
		type: "likert",
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 3,
		dataset: "be2",
		isNew: true,
	},
	{
		key: "b3",
		group: "student",
		hVar: "grade",
		surveys: [
			"no-bullying",
			"relationships-and-no-bullying",
			"learning-curve-wellbeing",
		],
		text: "Pushed, shoved, hit, spat at, kicked, tripped, punched or had your property broken or stolen.",
		responses: ["Daily", "Weekly", "Once or more a term", "Never"],
		type: "likert",
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 3,
		dataset: "be3",
		isNew: true,
	},
	{
		key: "b4",
		group: "student",
		hVar: "grade",
		surveys: [
			"no-bullying",
			"relationships-and-no-bullying",
			"learning-curve-wellbeing",
		],
		text: "Bullied online: embarrassed, upset, gossiped about, threatened, had rumours or lies spread about you, unwanted sharing or altering of images or video (by email, text, phone or social media).",
		responses: ["Daily", "Weekly", "Once or more a term", "Never"],
		type: "likert",
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 3,
		dataset: "be4",
		isNew: true,
	},
];

tranches.forEach(function (tr) {
	tr.items.forEach(function (pg) {
		pg.group = tr.group;
		pg.surveys = tr.surveys;
		pg.hVar = tr.hVar;
		pg.dataset = pg.key;
		if (tr.hasOwnProperty("isNew")) {
			pg.isNew = tr.isNew;
		} else {
			pg.isNew = false;
		}

		histograms.push(JSON.parse(JSON.stringify(pg)));
	});
});

export default histograms;
