
let questions = require('./qs.js'),
	qs = questions.qs;

let slopegraphs = [
	{
		group: "student",
		surveys: ["no-bullying","learning-curve-wellbeing"],
		key: "b5",
		title: "WHY students are being bullied",
		dataset: "be5",
		questions: Object.values(qs).filter(q => ['be5','bw5'].includes(q.key)),
		connectionToggle: true,
		optional: true,
		highlight: true,
		surveyOffering: "no-bullying"

	},
	{
		group: "student",
		surveys: ["no-bullying","learning-curve-wellbeing"],
		key: "b6",
		title: "WHERE students are being bullied",
		dataset: "be6",
		questions: Object.values(qs).filter(q => ['be6','bw6'].includes(q.key)),
		connectionToggle: true,
		optional: true,
		highlight: true,
		surveyOffering: "no-bullying"

	},
	{
		group: "student",
		surveys: ["no-bullying","learning-curve-wellbeing"],
		key: "b7",
		title: "WHO the bullies are",
		dataset: "be7",
		questions: Object.values(qs).filter(q => ['be7','bw7'].includes(q.key)),
		connectionToggle: true,
		optional: true,
		highlight: true,
		surveyOffering: "no-bullying"

	},
	{
		group: "student",
		surveys: ["no-bullying","learning-curve-wellbeing"],
		key: "b8",
		title: "HOW students respond to bullying",
		dataset: "be8",
		questions: Object.values(qs).filter(q => ['be8','bw8'].includes(q.key)),
		connectionToggle: true,
		optional: true,
		highlight: true,
		surveyOffering: "no-bullying"
	},
	{
		group: "student",
		surveys: ["no-bullying","learning-curve-wellbeing"],
		key: "b11",
		title: "What do you think about your school rules for bullying?",
		dataset: "b11",
		questions: Object.values(qs).filter(q => ['b11'].includes(q.key)),
		connectionToggle: false,
		optional: true,
		highlight: false,
		surveyOffering: "no-bullying"
	},
	{
		group: "student",
		surveys: ["no-bullying","learning-curve-wellbeing"],
		key: "b12",
		title: "Which of the following statements do you agree with?",
		dataset: "b12",
		questions: Object.values(qs).filter(q => ['b12'].includes(q.key)),
		connectionToggle: false,
		optional: true,
		highlight: false,
		surveyOffering: "no-bullying"
	},
	{
		group: "student",
		surveys: ["no-bullying","learning-curve-wellbeing"],
		key: "b13",
		title: "Which of the following aspects of bullying would you like to know more about?",
		dataset: "b13",
		questions: Object.values(qs).filter(q => ['b13'].includes(q.key)),
		connectionToggle: false,
		optional: true,
		highlight: false,
		surveyOffering: "no-bullying"

	},
	{
		group: "student",
		surveys: ["student-resilience"],
		key: "sm4",
		title: "In a typical week, what do you use social media for?",
		dataset: "sm4",
		questions: Object.values(qs).filter(q => ['sm4'].includes(q.key)),
		connectionToggle: false,
		optional: true,
		highlight: false,
		surveyOffering: "student-resilience"
	},
	{
		group: "parent",
		surveys: ["parent-resilience"],
		key: "rn71",
		title: "Which of the following kinds of parent/caregiver controls do you use to monitor or limit the online activity of your eldest child at this school?",
		dataset: "rn71",
		questions: Object.values(qs).filter(q => q.key == 'rn71'),
		connectionToggle: false,
		optional: false,
		highlight: false,
		surveyOffering: "parent-resilience"
	},
	{
		group: "parent",
		surveys: ["parent-resilience"],
		key: "rn72",
		title: "Which of the following risks do you feel your eldest child is exposed to?",
		dataset: "rn72",
		questions: Object.values(qs).filter(q => q.key == 'rn72'),
		connectionToggle: false,
		optional: false,
		highlight: false,
		surveyOffering: "parent-resilience"
	},
	{
		group: "parent",
		surveys: ["parent-resilience"],
		key: "rn73",
		title: "Thinking of your eldest child at this school, which of the following statements do you agree with?",
		dataset: "rn73",
		questions: Object.values(qs).filter(q => q.key == 'rn73'),
		connectionToggle: false,
		optional: false,
		highlight: false,
		surveyOffering: "parent-resilience"
	},

	{
		group: "parent",
		surveys: ["parent-resilience"],
		key: "rn60",
		title: "My eldest child at this school...",
		dataset: "rn60",
		questions: Object.values(qs).filter(q => q.key == 'rn60'),
		connectionToggle: false,
		optional: false,
		highlight: false,
		surveyOffering: "parent-resilience"
	},
	{
		group: "parent",
		surveys: ["parent-resilience"],
		key: "rn61",
		title: "My eldest child at this school is...",
		dataset: "rn61",
		questions: Object.values(qs).filter(q => q.key == 'rn61'),
		connectionToggle: false,
		optional: false,
		highlight: false,
		surveyOffering: "parent-resilience"
	},
	{
		group: "parent",
		surveys: ["parent-resilience"],
		key: "rn62",
		title: "My eldest child at this school and I regularly...",
		dataset: "rn62",
		questions: Object.values(qs).filter(q => q.key == 'rn62'),
		connectionToggle: false,
		optional: false,
		highlight: false,
		surveyOffering: "parent-resilience"
	},
	{
		group: "parent",
		surveys: ["parent-resilience"],
		key: "rn81",
		title: "My child:",
		dataset: "rn81",
		questions: Object.values(qs).filter(q => q.key == 'rn81'),
		connectionToggle: false,
		optional: false,
		highlight: false,
		surveyOffering: "parent-resilience"
	},
	{
		group: "parent",
		surveys: ["parent-resilience"],
		key: "rn82",
		title: "The School:",
		dataset: "rn82",
		questions: Object.values(qs).filter(q => q.key == 'rn82'),
		connectionToggle: false,
		optional: false,
		highlight: false,
		surveyOffering: "parent-resilience"
	},
	{
		group: "parent",
		surveys: ["parent-resilience"],
		key: "rn74",
		title: "Thinking of your parenting/caregiving, which of the following statements do you agree with?",
		dataset: "rn74",
		questions: Object.values(qs).filter(q => q.key == 'rn74'),
		connectionToggle: false,
		optional: false,
		highlight: false,
		surveyOffering: "parent-resilience"
	},
	{
		group: "parent",
		surveys: ["parent-resilience"],
		key: "rn75",
		title: "Thinking of your eldest child at this school, which of the following statements do you agree with?",
		dataset: "rn75",
		questions: Object.values(qs).filter(q => q.key == 'rn75'),
		connectionToggle: false,
		optional: false,
		highlight: false,
		surveyOffering: "parent-resilience"
	},
	{
		group: "parent",
		surveys: ["parent-resilience"],
		key: "rn76",
		title: "Thinking of this school, which of the following statements do you agree with?",
		dataset: "rn76",
		questions: Object.values(qs).filter(q => q.key == 'rn76'),
		connectionToggle: false,
		optional: false,
		highlight: false,
		surveyOffering: "parent-resilience"
	},
	{
		group: "staff",
		surveys: ["school-staff-resilience"],
		hvar: "agegroup",
		key: "rn111",
		title: "I have personally been harassed, bullied or threatened in the last 12 months with respect to:",
		dataset: "rn111",
		questions: Object.values(qs).filter(q => q.key == 'rn111'),
		connectionToggle: false,
		optional: false,
		highlight: false,
		surveyOffering: "school-staff-resilience"
	},
]

export default slopegraphs;